<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入名称" v-model.trim="listQuery.otherName" style="width:200px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px 15px;width:400px" class="styleForm" size="small" label-width="100px">
          <el-form-item label="摘要 ：">   
            <el-input  v-model="listQuery.bz" placeholder="请输入摘要" style="width:209px;" clearable></el-input>
          </el-form-item>
          <el-form-item label="收支类型 ："> 
            <el-select v-model="listQuery.type" placeholder="请选择收支类型" filterable style="width:209px;margin-left: 0;">
              <el-option label="全部" :value="null">
              </el-option>
              <el-option label="收入" :value="1">
              </el-option>
              <el-option label="支出" :value="2">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="金额区间 ：">
            <el-input v-model="listQuery.startAmount" placeholder="金额起" style="width:100px" @change="(e)=>{listQuery.startAmount= e? Number(e):null}"></el-input>
            <span style="margin: 0 2px;">-</span>
            <el-input v-model="listQuery.endAmount" placeholder="金额止" style="width:100px" @change="(e)=>{listQuery.endAmount= e?Number(e):null}"></el-input>
          </el-form-item>
          <el-form-item label="科目1 ：">   
            <el-input  v-model="listQuery.kemu1" placeholder="请输入科目1" style="width:209px;" clearable></el-input>
          </el-form-item>
        </el-form>
      </search>
      </div>
      <div></div>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      stripe
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column prop="id" label="id" width="55" align="center" />
      <el-table-column prop="otherName" label="名称" min-width="150" />
      <el-table-column prop="bz" label="摘要" min-width="150">
      </el-table-column>
      <el-table-column label="收支类型" width="80" align="center">
        <template #default="scope">
          <span v-if="scope.row.type == 1">收入</span>
          <span v-if="scope.row.type == 2">支出</span>
        </template>
      </el-table-column>
      <el-table-column label="金额" width="150" prop="amount">
      </el-table-column>
      <el-table-column label="科目1" min-width="180" prop="kemu1">
      </el-table-column>
      <el-table-column label="科目2" min-width="180" prop="kemu2">
      </el-table-column>
      <el-table-column label="备注" width="180" prop="remark">
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" size="small" plain @click="edit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="编辑银行"
    width="600px"
  >
    <el-form label-width="95px" :model="form" size="small">
      <el-form-item label="名称：">
        <el-input v-model="form.otherName" style="width:394px" disabled />
      </el-form-item>
      <el-form-item label="摘要：">
        <el-input v-model="form.bz" style="width:394px" disabled />
      </el-form-item>
      <el-form-item label="科目2：">
        <el-input v-model="form.kemu2" style="width:394px" placeholder="科目2"/>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input
          placeholder="备注"
          v-model="form.remark"
          style="width:394px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveSure()" size="small">
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { bankInfoList, updateBankInfo } from "@/api/bank";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
      },
      list: [],
      total: 0,
      contentStyleObj: {},
      form: {},
      dialogVisible: false,
      loading:false
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(210);
    this.getList();
  },
  methods: {
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
      }
      this.getList()
    },
    getList() {
      this.loading = true
      bankInfoList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    edit(row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    saveSure() {
      updateBankInfo(this.form).then((res) => {
        if (res.data.msg == "success") {
          this.getList();
          this.$qzfMessage("修改成功");
          this.dialogVisible = false
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
